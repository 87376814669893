@import '../variables.css';
@import '_background-pattern.css';
@import '_plex-font-face.css';
@import '_caslon-graphique-font-face.css';
@import-normalize;

:root {
	accent-color: var(--blue);
}

html {
	box-sizing: border-box;
}

*, *::before, *::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	color: var(--text-color);
	font: 400 100%/1.5 var(--font-fam);
}

input,
select,
textarea,
button {
	font-family: inherit;
}

::selection {
	background: var(--action-background-l2);
}

a {
	transition: color 0.3s ease;
	color: var(--blue);
	text-decoration: none;

	@media (any-hover) {
	&:hover {
		color: var(--blue-secondary);
	}

	&:active {
		color: var(--darkgrey);
	}
	}
}

img {
	border: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

.cf {
	&:before,
	&:after {
	  content: "";
	  display: table;
	}

	&:after {
	  clear: both;
	}
}

.sr-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}


@media (inverted-colors) {
img {
	filter: invert(100%);
}
}
